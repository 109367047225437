<template>
  <div>
    <!-- table add values -->
    <b-card title="Table Detail" class="mt-2" style="margin-bottom:5rem">
      <validation-observer ref="tableAddRules">
        <b-form>
          <hr />
          <b-row class="mt-1" style="height: 30vh;">
            <b-col
              md="6"
              xl="3"
              class="mb-1"
              v-for="(input, index) in inputs"
              :key="index"
            >
              <b-form-group :label="input.title.toUpperCase()">
                <!-- input -->
                <div class="d-flex justify-content-center align-items-center">
                  <validation-provider
                    #default="{ errors }"
                    :name="input.title"
                    rules="required"
                    style="width: 100%;"
                  >
                    <b-form-input
                      class="animate__animated animate__fadeIn"
                      v-model="input.value"
                      :state="errors.length > 0 ? false : null"
                      type="text"
                      id="basicInput"
                      :placeholder="input.title"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row align-h="end">
            <b-col md="12" xl="3" class="mb-1">
              <b-button
                @click="addToTable"
                variant="gradient-success"
                style="width: 100%;"
              >
                Add to Table
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>

    <!-- Tables -->
    <b-overlay :show="loading" rounded="sm" no-fade>
      <b-card title="Tables" class="mt-2">
        <b-row>
          <b-table
            class="w-100"
            responsive="sm"
            :items="tableValues"
            :fields="tableHeaders"
            fixed
            empty-text="No matching records found"
            :current-page="currentPage"
            :per-page="perPage"
          >
            <template #cell(index)="data">
              <strong class="text-primary"> #{{ data.index + 1 }} </strong>
            </template>

            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>

                <b-dropdown-item @click="updateTableModal(data.item)">
                  <feather-icon icon="FileTextIcon" />
                  <span class="align-middle ml-50">Update</span>
                </b-dropdown-item>
                <b-dropdown-item @click="deleteTableValue(data.item.id)">
                  <feather-icon icon="DeleteIcon" />
                  <span class="align-middle ml-50">Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
          <div class="mx-2 mb-2 mt-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"
                  >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
                  {{ dataMeta.of }} entries</span
                >
              </b-col>

              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-row>
      </b-card>
    </b-overlay>

    <!-- update formul modal -->
    <b-modal
      id="modal-prevent-closing"
      ref="modal"
      title="Edit Table Values"
      scrollable
      size="xl"
      ok-title="Update"
      modal-class="modal-height"
      v-model="showUpdateValues"
      @hidden="resetInputValues"
      @ok="updateValue"
    >
      <validation-observer ref="updateValueRules">
        <b-form>
          <b-row class="mt-1 mb-5">
            <b-col
              md="6"
              xl="3"
              class="mb-1"
              v-for="(input, index) in updateInputs"
              :key="index"
            >
              <b-form-group :label="input.title.toUpperCase()">
                <div class="d-flex justify-content-center align-items-center">
                  <validation-provider
                    #default="{ errors }"
                    :name="input.title"
                    rules="required"
                    style="width: 100%;"
                  >
                    <b-form-input
                      class="animate__animated animate__fadeIn"
                      v-model="input.value"
                      :state="errors.length > 0 ? false : null"
                      type="text"
                      id="basicInput"
                      :placeholder="input.title"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BLink,
} from "bootstrap-vue";
import { kFormatter } from "@core/utils/filter";
import moment from "moment";
import formulStoreModule from "../formulStoreModule";
import store from "@/store";
import { ref, onUnmounted, reactive } from "@vue/composition-api";
import useJwt from "@/auth/jwt/useJwt";
import router from "@/router";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import vSelect from "vue-select";
import { BTable } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import "animate.css";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, alpha } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import _ from "lodash";

export default {
  components: {
    BLink,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    vSelect,
    BTable,
    AppTimeline,
    AppTimelineItem,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },

  setup() {
    const FORMUL_APP_STORE_MODULE_NAME = "formul";
    // Register module
    if (!store.hasModule(FORMUL_APP_STORE_MODULE_NAME))
      store.registerModule(FORMUL_APP_STORE_MODULE_NAME, formulStoreModule);
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FORMUL_APP_STORE_MODULE_NAME))
        store.unregisterModule(FORMUL_APP_STORE_MODULE_NAME);
    });
  },

  mounted() {
    this.getTable(this.$route.params.tableName);
    this.getTableValues();
  },

  data() {
    const dateNow = moment(new Date()).format("YYYY-MM-DD");

    return {
      dateNow,
      userDataInfo: null,
      currentPage: 1,
      pageOptions: [5, 10, 15, 20],
      totalRows: 0,
      perPage: 10,
      tableHeaders: [],
      tableValues: [],
      inputs: [],
      tableData: [],
      loading: false,

      //update formul modal
      updateId: 0,
      updateInputs: [],
      showUpdateValues: false,
      updateOperations: [],
    };
  },
  computed: {
    dataMeta() {
      return {
        from:
          this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to:
          this.perPage * (this.currentPage - 1) + Number(this.perPage) <
          this.totalRows
            ? this.perPage * (this.currentPage - 1) + Number(this.perPage)
            : this.totalRows,
        of: this.totalRows,
      };
    },
  },

  methods: {
    kFormatter,

    dateFormat(val) {
      return moment(val).format("DD-MM-YYYYY HH:mm");
    },

    addToTable() {
      this.$refs.tableAddRules.validate().then((success) => {
        if (success) {
          // combining the inputs title value to new object
          let myObject = {};
          this.inputs.forEach((i) => {
            let obj = {};
            obj[i.title] = i.value;
            Object.assign(myObject, obj);
          });
          //

          this.$store
            .dispatch("formul/addTableValue", {
              tableName: this.$route.params.tableName,
              values: myObject,
            })
            .then((res) => {
              this.inputs.forEach((i) => {
                i.value = null;
              });

              this.getTableValues()
            })
            .catch((error) => {
              console.log("error table add value ", error);
            });
        }
      });
    },

    getTable(name) {
      if (name != undefined) {
        this.$store
          .dispatch("formul/getSelectedTable", name)
          .then((res) => {
            if (res.data.inputs.length > 0) {
              this.inputs = [];
              res.data.inputs.forEach((input) => {
                this.inputs.push({
                  title: input,
                  value: null,
                });
              });
            }
            if (res.data.headers.length > 0) {
              this.tableHeaders = [];
              this.tableHeaders.push({
                key: "index",
                label: "",
                sortable: true,
                class: "text-center",
              });
              res.data.headers.forEach((header) => {
                this.tableHeaders.push({
                  key: header,
                  label: header,
                  thClass: "text-center",
                  tdClass: "text-center",
                  sortable: true,
                });
              });
              this.tableHeaders.push({
                key: "actions",
                label: "ACTIONS",
                thClass: "text-center",
                tdClass: "text-center",
              });
            }

            this.loading = false;
          })
          .catch((error) => {
            console.log("error get table ", error);
          });
      }
    },

    getTableValues() {
      this.loading = true;
      this.$store
        .dispatch("formul/fetchTableValues", this.$route.params.tableName)
        .then((res) => {
          this.totalRows = res.data.length;
          this.tableValues = [];
          this.tableValues = res.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log("error get table values data ", error);
        });
    },

    //update modal functions

    updateTableModal(data) {
      this.updateId = data.id;
      this.updateInputs = _.map(data, (value, title) => ({ value, title }));
      this.updateInputs = _.remove(this.updateInputs, function(currentObject) {
        return currentObject.title !== "id" && currentObject.title !== "date";
      });

      if (this.updateInputs.length > 0) {
        this.showUpdateValues = true;
      }
    },
    updateValue(bvModalEvent) {
      bvModalEvent.preventDefault();

      this.$refs.updateValueRules.validate().then((success) => {
        if (success) {
          // combining the inputs title value to new object
          let updateObject = {};
          this.updateInputs.forEach((i) => {
            let obj = {};
            obj[i.title] = i.value;
            Object.assign(updateObject, obj);
          });
          //
          if (this.updateId != 0) {
            this.$store
              .dispatch("formul/updateTableValue", {
                id: this.updateId,
                tableName: this.$route.params.tableName,
                updateData: updateObject,
              })
              .then((res) => {
                this.getTableValues();
                this.resetInputValues();
                this.editToastMessage("Data");
              })
              .catch((error) => {
                console.log("error update data ", error);
              });
          }
        }
      });
    },

    deleteTableValue(id) {
      const self = this;
      if (id) {
        this.$swal({
          title: "Are you sure you want to delete the value?",
          // text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
          customClass: {
            confirmButton: "btn btn-success mr-1",
            cancelButton: "btn btn-danger",
            denyButton: "btn btn-primary",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            //   this.$store
            //     .dispatch("formul/deleteFormul", id)
            //     .then((res) => {
            //       self.getTableValues();
            //       self.$swal.fire(
            //         "Deleted!",
            //         "Formul has been deleted.",
            //         "success"
            //       );
            //     })
            //     .catch((error) => {
            //       console.log("error update data ", error);
            //     });
          }
        });
      }
    },
    resetInputValues() {
      this.updateId = 0;
      this.updateInputs = [];
      this.showUpdateValues = false;
    },

    //***************** update modal */

    toastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successful",
          text: "✔️ " + val + " Added Successful",
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    editToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successful",
          text: "✔️ " + val + " Updated Successful",
          icon: "CheckIcon",
          variant: "success",
        },
      });
    },
    lastValueErrorToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text:
            "The last value cannot be a math operation. please check the formula",
          icon: "AlertCircleIcon",
          variant: "danger",
        },
      });
    },
    firstValueErrorToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text:
            "The first value cannot be a math operation. please check the formula",
          icon: "AlertCircleIcon",
          variant: "danger",
        },
      });
    },
    checkTitleToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: "This title is used. Please check formula title",
          icon: "AlertCircleIcon",
          variant: "warning",
        },
      });
    },
    checkFormulaToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: "This formula cannot be formula only. Please add other inputs",
          icon: "AlertCircleIcon",
          variant: "danger",
        },
      });
    },
  },
  watch: {},
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style>
.input-dropdown .btn {
  padding: 0;
}
.checkbox-label .custom-control-label {
  font-size: 0.857rem !important;
}
.modal-height .modal-dialog .modal-content {
  height: 50vh !important;
}
</style>
